<template>
  <section class="view-overdue-drivers">
    <b-row class="pb-4">
      <b-col cols="6">
        <h2>
          Motoristas Inadimplentes
        </h2>
      </b-col>
    </b-row>

    <custom-data-table :fields="fields" :filters="filters" :query="overdue_drivers_query" query-node="overdueDrivers"
      @vuetable:row-clicked="item => $router.push(`/drivers/profile/${item.data.id}/wallet`)">
      <span slot="name" slot-scope="props">{{ props.rowData.name || 'Anônimo' }}</span>
      <span slot="cpf" slot-scope="props">{{ props.rowData.cpf | format_document }}</span>
      <span slot="days_overdue" slot-scope="props">{{ props.rowData.days_overdue | plural('dia', 'dias') }}</span>
      <span slot="qt_invoices" slot-scope="props">{{ props.rowData.qt_invoices | plural('transação', 'transações')
      }}</span>
      <span slot="amount_overdue" slot-scope="props">
        {{ props.rowData.amount_overdue | money_intl }}
      </span>
      <span slot="renting" slot-scope="props"> {{ props.rowData.booking_status | booking_status }} </span>
      <span slot="driver_status" slot-scope="props">
        <b-badge :variant="props.rowData.driver_status | status_variant">
          {{ props.rowData.driver_status }}
        </b-badge>
      </span>
    </custom-data-table>
  </section>
</template>

<script>
import CustomDataTable from '@components/CustomDataTable'

import OVERDUE_DRIVERS_QUERY from '@graphql/driver/queries/overdue-drivers.gql';
import OVERDUE_DRIVERS_SUMMARY_QUERY from '@graphql/driver/queries/overdue-drivers-summary.gql';

export default {
  components: {
    CustomDataTable
  },
  data() {
    return {
      overdue_drivers_query: OVERDUE_DRIVERS_QUERY,
      overdue_drivers_summary_query: OVERDUE_DRIVERS_SUMMARY_QUERY,
      fields: [{
        name: 'name',
        title: 'Nome',
        sortField: 'name'
      },
      {
        name: 'cpf',
        title: 'CPF',
        sortField: 'cpf'
      },
      {
        name: 'days_overdue',
        title: 'Dias em atraso',
        sortField: 'days_overdue'
      },
      {
        name: 'qt_invoices',
        title: 'Qtde. em atraso',
        sortField: 'qt_invoices'
      },
      {
        name: 'amount_overdue',
        title: 'R$ em atraso',
        sortField: 'amount_overdue'
      },
      {
        name: 'renting',
        title: 'Alugando'
      },
      {
        name: 'driver_status',
        title: 'Status Motorista',
      },
      ],
      filters: {
        query: {
          type: 'textfield',
          props: {
            label: 'Busca por nome/CPF',
            placeholder: 'Buscar motorista inadimplente...',
          },
          graphqlQuery(value) {
            return {
              $or: [{
                name: {
                  $like: `%${value}%`,
                },
              },
              {
                cpf: {
                  $like: `%${value}%`,
                },
              }
              ],
            }
          },
        },
        days_overdue: {
          type: 'select',
          cols: 2,
          props: {
            label: 'Dias em atraso',
            options: [{
              value: null,
              text: 'Selecionar'
            },
            {
              value: 1,
              text: '1'
            },
            {
              value: 2,
              text: '2'
            },
            {
              value: 3,
              text: '3'
            },
            {
              value: 4,
              text: '4'
            },
            {
              value: 5,
              text: '5'
            },
            {
              value: 6,
              text: '6'
            },
            {
              value: 7,
              text: '7'
            },
            {
              value: 8,
              text: '8'
            },
            ],
          },
          graphqlQuery(value) {
            return {
              days_overdue: {
                $gte: Number(value)
              }
            }
          }
        },
        kind: {
          type: 'select',
          cols: 2,
          props: {
            label: 'Tipo da transação',
            options: [{
              value: null,
              text: 'Todos'
            },
            {
              value: 'RECURRENCY',
              text: 'Recorrência'
            },
            {
              value: 'FINE',
              text: 'Multa'
            },
            {
              value: 'NIC',
              text: 'Não Indicação'
            },
            {
              value: 'THEFT',
              text: 'Roubo'
            },
            {
              value: 'MAINTENANCE',
              text: 'Manutenção'
            },
            {
              value: 'RECOVER',
              text: 'Recuperação'
            },
            {
              value: 'PRE_PAYMENT',
              text: 'Caução'
            },
            {
              value: 'PRE_PAYMENT_RTO',
              text: 'Caução RTO'
            },
            {
              value: 'PRE_PAYMENT_PROMOTIONAL',
              text: 'Promocional'
            },
            {
              value: 'PRE_PAYMENT_RESERVE',
              text: 'Reserva'
            },
            {
              value: 'DESMOB_PRE_PAYMENT',
              text: 'Desmobilização'
            },
            {
              value: 'FIRST_PAYMENT',
              text: '1º Semana'
            },
            {
              value: 'FIRST_PAYMENT_EXCHANGE',
              text: 'Troca'
            },
            {
              value: 'OTHERS',
              text: 'Outros'
            },
            {
              value: 'DEPOSIT',
              text: 'Entrada de compra de veículo'
            },
            ],
          },
          graphqlQuery(value) {
            return {
              kind: value,
            }
          }
        },
        driver_status: {
          type: 'select',
          cols: 2,
          props: {
            label: 'Status Motorista',
            options: [{
              value: null,
              text: 'Todos'
            },
            {
              value: 'ACTIVE',
              text: 'Ativo'
            },
            {
              value: 'BANNED',
              text: 'Banido'
            },
            {
              value: 'INACTIVE',
              text: 'Inativo'
            },
            ],
          },
          graphqlQuery(value) {
            return {
              driver_status: [value]
            }
          }
        },
        booking_status: {
          type: 'select',
          cols: 2,
          props: {
            label: 'Status Booking',
            options: [{
              value: null,
              text: 'Todos'
            },
            {
              value: 'ACTIVE',
              text: 'Ativo'
            },
            {
              value: 'PENDING',
              text: 'Pendente'
            },
            {
              value: 'CLOSED',
              text: 'Fechado'
            },
            {
              value: 'CANCELLED',
              text: 'Cancelado'
            },
            ],
          },
          graphqlQuery(value) {
            return {
              booking_status: [value]
            }
          }
        }
      }
    }
  }
}
</script>
