var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "view-overdue-drivers" },
    [
      _c(
        "b-row",
        { staticClass: "pb-4" },
        [
          _c("b-col", { attrs: { cols: "6" } }, [
            _c("h2", [_vm._v(" Motoristas Inadimplentes ")]),
          ]),
        ],
        1
      ),
      _c("custom-data-table", {
        attrs: {
          fields: _vm.fields,
          filters: _vm.filters,
          query: _vm.overdue_drivers_query,
          "query-node": "overdueDrivers",
        },
        on: {
          "vuetable:row-clicked": (item) =>
            _vm.$router.push(`/drivers/profile/${item.data.id}/wallet`),
        },
        scopedSlots: _vm._u([
          {
            key: "name",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(_vm._s(props.rowData.name || "Anônimo")),
              ])
            },
          },
          {
            key: "cpf",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(_vm._s(_vm._f("format_document")(props.rowData.cpf))),
              ])
            },
          },
          {
            key: "days_overdue",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(
                    _vm._f("plural")(props.rowData.days_overdue, "dia", "dias")
                  )
                ),
              ])
            },
          },
          {
            key: "qt_invoices",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(
                    _vm._f("plural")(
                      props.rowData.qt_invoices,
                      "transação",
                      "transações"
                    )
                  )
                ),
              ])
            },
          },
          {
            key: "amount_overdue",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("money_intl")(props.rowData.amount_overdue)) +
                    " "
                ),
              ])
            },
          },
          {
            key: "renting",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("booking_status")(props.rowData.booking_status)
                    ) +
                    " "
                ),
              ])
            },
          },
          {
            key: "driver_status",
            fn: function (props) {
              return _c(
                "span",
                {},
                [
                  _c(
                    "b-badge",
                    {
                      attrs: {
                        variant: _vm._f("status_variant")(
                          props.rowData.driver_status
                        ),
                      },
                    },
                    [_vm._v(" " + _vm._s(props.rowData.driver_status) + " ")]
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }